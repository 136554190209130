import { Directive, effect, ElementRef, inject, input } from "@angular/core";
import { Router } from "@angular/router";
import { Link } from "@cg/content-api/typescript-interfaces";

@Directive({
  selector: "[cgxOpenLink]",
  standalone: true
})
export class OpenLinkDirective {
  private readonly el = inject(ElementRef);
  public readonly router = inject(Router);

  public link = input.required<Link>();

  public constructor() {
    effect(() => {
      const cursor = this.link().href ? "pointer" : "default";
      this.el.nativeElement.style.cursor = cursor;

      this.el.nativeElement.removeEventListener("click", this.openLink.bind(this));
      this.el.nativeElement.addEventListener("click", this.openLink.bind(this));
    });
  }

  public openLink(): void {
    if (!this.link()) {
      return;
    }

    if (this.link().routerLink) {
      this.router.navigateByUrl(this.link().href);
      return;
    }

    window.open(this.link().href, this.link().target ?? "_blank");
  }
}
