import { NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, model, output, TemplateRef } from "@angular/core";
import { ExpansionPanel } from "../../interfaces/expansion-panel.interface";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "cgx-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, IconComponent, NgStyle]
})
export class ExpansionPanelComponent {
  public content = input<ExpansionPanel>();
  public contentTemplate = input<TemplateRef<unknown>>();
  public titleTemplate = input<TemplateRef<unknown>>();
  public expanded = model<boolean>(false);

  // Estimation of max height to minimize animation delay due to max height transitioning from 100vh to 0px.
  public maxHeight = input<string>("100vh");

  public expandedChanged = output<boolean>();

  public handleExpandedChanged() {
    this.expanded.set(!this.expanded());
    this.expandedChanged.emit(this.expanded());
  }
}
