import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "cgx-youtube",
  templateUrl: "./youtube.component.html",
  styleUrls: ["./youtube.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class YoutubeComponent {
  private readonly sanitizer = inject(DomSanitizer);
  private readonly YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/";

  public youtubeId = input.required<string>();

  public videoUrl = computed(() =>
    this.sanitizer.bypassSecurityTrustResourceUrl(`${this.YOUTUBE_EMBED_URL}${this.youtubeId()}?rel=0&enablejsapi=1`)
  );
}
