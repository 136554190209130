import { Inject, Injectable } from "@angular/core";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";

@Injectable({
  providedIn: "root"
})
export class HtmlContentParserService {
  public constructor(@Inject(IS_SERVER_PLATFORM) public readonly isServer: boolean) {}

  public replacePlaceholder(htmlString: string | null): string | null {
    if (this.isServer || !htmlString) {
      return htmlString;
    }

    htmlString = this.replaceUsercentricLinksWithAnchorElements(htmlString);
    htmlString = this.replaceOpenHoursPlaceholderWithComponent(htmlString);

    return htmlString;
  }

  private replaceUsercentricLinksWithAnchorElements(htmlString: string): string {
    const regex = /\[UsercentricsLink title=(&quot;)([\w\-&auml;&Auml;&uuml;&Uuml;&ouml;&Ouml;]*)(&quot;)\]/g;
    let result;
    const aTagElement = document.createElement("a");
    aTagElement.setAttribute("onclick", "UC_UI.showSecondLayer();");
    while ((result = regex.exec(htmlString)) && result.length > 0) {
      const title = result[2];
      aTagElement.innerHTML = title;
      const searchValue = `[UsercentricsLink title=&quot;${title}&quot;]`;
      htmlString = htmlString.replace(searchValue, aTagElement.outerHTML);
    }
    return htmlString;
  }

  private replaceOpenHoursPlaceholderWithComponent(htmlString: string): string {
    return htmlString.replace("[OPENING_HOURS]", "");
  }
}
