import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@cg/environments";
@Injectable({
  providedIn: "root"
})
export class IconRegistryService {
  private _iconSet = new Set<string>();

  public constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  private static _returnIdentifier(iconName: string, url: string): string {
    return `${iconName}|${url}`;
  }

  public addSvgIcon(iconName: string, url: string): void {
    const hash = IconRegistryService._returnIdentifier(iconName, url);

    if (!this._iconSet.has(hash)) {
      if (url.startsWith("/dam")) {
        // Magnolia icons sometimes dont have the full path, so we need to prepend the host in order to make them work
        url = `https://${environment.webContentApi}${url}`;
      }
      this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(url));
      this._iconSet.add(hash);
    }
  }
}
