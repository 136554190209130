import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { computed, DestroyRef, Directive, effect, ElementRef, inject, input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Subscription } from "rxjs";
import { PictureSize } from "@cg/content-api/typescript-interfaces";
import { PictureMediaQueries } from "@cg/core/ui";
import { environment } from "@cg/environments";

@Directive({
  selector: "[cgxResponsiveBg]",
  standalone: true
})
export class ResponsiveBgDirective {
  private readonly el = inject(ElementRef);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly destoryRef = inject(DestroyRef);

  public src = input.required<{ source: string; sizes: PictureSize[] }>();

  private _source = signal<string>("");
  private _sizes = signal<PictureSize[]>([]);
  private _queries = computed(() => this._sizes().map((picSize: PictureSize) => PictureMediaQueries[picSize.media]));
  private _subscription: Subscription;

  public constructor() {
    effect(
      () => {
        this._source.set(this.src().source);
        this._sizes.set(this.src().sizes);
        this._setBgImgUrl(this._source());

        if (this._subscription) {
          this._subscription.unsubscribe();
        }

        if (this._queries().length > 0) {
          this._subscription = this.breakpointObserver
            .observe(this._queries())
            .pipe(takeUntilDestroyed(this.destoryRef))
            .subscribe((result: BreakpointState) => {
              if (!result.matches) {
                this._setBgImgUrl(this._source());
                return;
              }

              const breakpoint = Object.keys(result.breakpoints).find((key: string) => result.breakpoints[key]);
              const sizeKey = Object.keys(PictureMediaQueries).find(
                (key: string) => PictureMediaQueries[key] === breakpoint
              );
              const bgImgUrl = this._sizes().find((picSize: PictureSize) => picSize.media === sizeKey).srcset;
              this._setBgImgUrl(bgImgUrl);
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  private _setBgImgUrl(bgImgUrl: string) {
    if (bgImgUrl.startsWith("/dam/") || bgImgUrl.startsWith("/.imaging/")) {
      // Magnolia images sometimes dont have the full path, so we need to prepend the host in order to make them work
      bgImgUrl = `https://${environment.webContentApi}${bgImgUrl}`;
    }

    this.el.nativeElement.style.backgroundImage = `url(${bgImgUrl})`;
  }
}
