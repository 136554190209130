import { NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  signal
} from "@angular/core";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";

@Component({
  selector: "cgx-slider",
  templateUrl: "./slider.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class SliderComponent implements AfterViewInit, OnDestroy {
  private readonly _elementRef = inject(ElementRef);
  private readonly _isServer = inject(IS_SERVER_PLATFORM);
  private _intersectionObserver: IntersectionObserver;

  public pageCount = signal<number>(0);
  public currentPage = signal<number>(0);

  public pageIndexes = computed(() =>
    Array(this.pageCount())
      .fill("")
      .map((_: string, i: number) => i)
  );

  public ngAfterViewInit(): void {
    if (this._isServer) {
      return;
    }

    const sliderItems = this._getSliderItems();

    if (!sliderItems.length) {
      return;
    }

    this.pageCount.set(sliderItems.length);

    this._initIntersectionObserver();
    this._setDatasetForSliderItems(sliderItems);

    if (this._isServer) {
      return;
    }

    window.addEventListener("resize", this._handleResize.bind(this));
  }

  public ngOnDestroy(): void {
    if (this._isServer) {
      return;
    }

    this._intersectionObserver?.disconnect();
    window.removeEventListener("resize", this._handleResize.bind(this));
  }

  private _getSliderItems() {
    // Use plain js query to get elements. Not possible with viewChilden here.
    return this._elementRef.nativeElement.querySelectorAll("cgx-slider-item");
  }

  private _initIntersectionObserver() {
    this._intersectionObserver = new IntersectionObserver(
      ([e]: [IntersectionObserverEntry]) => {
        if (e.intersectionRatio < 1) {
          return;
        }
        const currentPage = parseInt((e.target as HTMLElement).dataset.pageIndex, 10);
        this.currentPage.set(currentPage);
      },
      { threshold: [1] }
    );

    for (const sliderItem of this._getSliderItems()) {
      this._intersectionObserver.observe(sliderItem);
    }
  }

  private _setDatasetForSliderItems(sliderItems: HTMLElement[]) {
    for (let i = 0; i < sliderItems.length; i++) {
      const sliderItem = sliderItems[i];
      sliderItem.dataset.pageIndex = i.toString();
    }
  }

  private _handleResize() {
    for (const item of this._getSliderItems()) {
      const rect = item.getBoundingClientRect();
      if (rect.left < 0 || rect.right > window.innerWidth) {
        continue;
      }

      this.currentPage.set(parseInt(item.dataset.pageIndex, 10));
    }
  }
}
