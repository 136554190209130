import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { IconRegistryService } from "../../services/icon-registry.service";

@Component({
  selector: "cgx-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, MatIconModule]
})
export class IconComponent {
  private readonly iconRegistry = inject(IconRegistryService);

  public content = input(
    {
      name: "",
      src: "",
      ngTemplate: "icon"
    },
    {
      transform: (val: Icon) => {
        this.iconRegistry.addSvgIcon(val.name, val.src);
        return val;
      }
    }
  );

  public isSvg = computed(() => this.content()?.src?.split(".").pop().toLowerCase() === "svg");
}
