<div
  [ngClass]="{ 'border-b-2 border-cg-gray-light-70': !titleTemplate() }"
  class="flex flex-col overflow-hidden font-roboto text-cg-anthracite"
  data-cy="expansion-panel"
>
  <div (click)="handleExpandedChanged()" class="cursor-pointer">
    @if (titleTemplate()) {
      <span>
        <ng-container *ngTemplateOutlet="titleTemplate()"></ng-container>
      </span>
    }
    @if (!titleTemplate()) {
      <span class="flex flex-row items-center justify-between p-4">
        <div class="text-base font-bold text-cg-anthracite" data-cy="expansion-panel-title">
          {{ content()?.title }}
        </div>
        <cgx-icon
          [content]="content()?.icon"
          [ngClass]="{ 'origin-center rotate-180 transform transition-transform duration-700': expanded() }"
          class="transition-transform duration-700 [--icon-size:12px]"
        ></cgx-icon>
      </span>
    }
  </div>
  @if (contentTemplate()) {
    <div
      [ngStyle]="{ 'max-height': expanded() ? maxHeight() : '0px' }"
      class="transform transition-[max-height] duration-700"
      data-cy="template-content"
    >
      <ng-container *ngTemplateOutlet="contentTemplate()"></ng-container>
    </div>
  }
</div>
