<div [ngClass]="content().alignment === 'center' ? 'flex justify-center' : ''">
  <a
    [id]="content().id"
    [attr.disabled]="disabled() ? true : null"
    class="flex cursor-pointer items-center justify-center gap-2 rounded-3xl py-3 transition-colors duration-300 ease-in-out"
    [ngClass]="computedClasses()"
    [href]="content().link?.href ?? '#'"
    [target]="content().link?.target ?? '_self'"
  >
    <span class="font-roboto-condensed font-bold">{{ content().title }}</span>
    @if (content().icon) {
      <cgx-icon
        class="[--icon-size:24px]"
        [ngClass]="{
          'origin-center rotate-180': content().iconDirection === 'left'
        }"
        [content]="content().icon"
      ></cgx-icon>
    }
  </a>
</div>
