import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CgxHeadline } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-headline",
  standalone: true,
  templateUrl: "./headline.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass]
})
export class HeadlineComponent {
  private readonly domSanitizer = inject(DomSanitizer);

  public content = input<CgxHeadline>();
  public sanitizedContent = computed(() => this.domSanitizer.bypassSecurityTrustHtml(this.content().title));
}
