import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SafeContentPipe } from "@cg/shared";
import type { CgxParagraph } from "@cg/content-api/typescript-interfaces";
import { HtmlContentParserService } from "../../services/html-content-parser.service";

@Component({
  selector: "cgx-paragraph",
  templateUrl: "./paragraph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SafeContentPipe]
})
export class ParagraphComponent {
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly htmlContentParserService = inject(HtmlContentParserService);

  public content = input<CgxParagraph>();
  public size = model<CgxParagraph["size"]>("m");
  public condensed = model<CgxParagraph["condensed"]>(false);
  public alignment = model<CgxParagraph["alignment"]>("left");

  public sanitizedContent = computed(() => {
    const updatedHtmlString = this.htmlContentParserService.replacePlaceholder(this.content()?.text ?? "");

    if (!updatedHtmlString) {
      return null;
    }

    const sanitizedMagnoliaHtmlString = this._sanitizeMagnoliaHtml(updatedHtmlString);

    return this.domSanitizer.bypassSecurityTrustHtml(sanitizedMagnoliaHtmlString);
  });

  public constructor() {
    effect(
      () => {
        const val = this.content();

        if (!val) {
          return;
        }

        this.size.set(val.size);
        this.condensed.set(val.condensed);
        this.alignment.set(val.alignment);
      },
      { allowSignalWrites: true }
    );
  }

  /**
   *
   * Sanitizes the HTML string from Magnolia by removing the <p> tags and replacing them with <br> tags.
   * Also replaces new lines with <br> tags and removes trailing <br> tags.
   *
   *  We need this because the richtext editor in Magnolia adds <p> tags to the text.
   *  This leads to an invalid result when bypassSecurityTrustHtml is used.
   *  Because Angular directly close the origin p tag of the paragraph and adds the content outside of the p tag.
   *  The result is that the css styles are not applied correctly.
   *
   **/
  private _sanitizeMagnoliaHtml(htmlString: string): string {
    const removeLinebreaksAndParagraphs = (val: string) => val.replace(/(<\/?p>|<br\s*\/?>)/g, "");
    const replaceLinebreaksWithBr = (val: string) => val.replace(/\n/g, "<br>");
    const limitEditorLinebreakToOne = (val: string) => val.replace(/(<br>){2}/g, "<br>");
    const removeTrailingLineBreaks = (val: string) => val.replace(/<br>$/, "");

    let result = removeLinebreaksAndParagraphs(htmlString);
    result = replaceLinebreaksWithBr(result);
    result = limitEditorLinebreakToOne(result);
    result = removeTrailingLineBreaks(result);

    return result.trim();
  }
}
