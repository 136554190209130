<picture class="relative flex max-w-fit flex-col">
  @for (item of content()?.sizes; track item) {
    <source [srcset]="item?.srcset || null" [media]="this.pictureMediaQueries[item?.media] || null" />
  }
  <img
    class="h-auto w-full"
    [attr.loading]="loading()"
    [src]="content()?.source || null"
    [alt]="!content()?.alt ? null : shouldTranslateAlt() ? (content().alt | transloco) : content().alt"
  />
</picture>
