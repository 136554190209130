<div class="flex flex-col gap-6">
  <div class="flex snap-x snap-mandatory gap-x-[var(--slider-gap,16px)] overflow-x-scroll [scrollbar-width:none]">
    <ng-content></ng-content>
  </div>

  <div class="flex items-center justify-center gap-2 cgxT:hidden">
    @for (pageIndex of pageIndexes(); track pageIndex) {
      <div
        class="size-4 rounded-full bg-cg-gray-light"
        [ngClass]="{ '!bg-cg-yellow': pageIndex === currentPage() }"
      ></div>
    }
  </div>
</div>
