import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import type { CgxToggle } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cgx-toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements ControlValueAccessor {
  public content = input.required<CgxToggle>();

  public get value(): boolean {
    return this._value;
  }

  private _value: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onChange: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onTouch: any;

  public writeValue(value: boolean): void {
    this._value = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  public onChange() {
    this._value = !this._value;
    this._onChange(this._value);
    this._onTouch();
  }
}
