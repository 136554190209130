import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import type { CgxPicture } from "@cg/content-api/typescript-interfaces";
import { PictureMediaQueries } from "../../enums/picture-media-queries.enum";

@Component({
  selector: "cgx-picture",
  templateUrl: "./picture.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe]
})
export class PictureComponent {
  public loading = input<"lazy" | "eager">("lazy");
  public content = input.required<CgxPicture>();
  public shouldTranslateAlt = input<boolean>(false);

  public pictureMediaQueries = PictureMediaQueries;
}
