import { ChangeDetectionStrategy, Component } from "@angular/core";
import { infoIconOutline } from "@cg/icon";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "cgx-info-btn",
  templateUrl: "./info-btn.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent]
})
export class InfoBtnComponent {
  public infoIcon = infoIconOutline;
}
