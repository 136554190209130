@if (content().type === "h1") {
  <h1
    class="font-carglass-condensed text-5xl font-medium text-cg-anthracite cgxT:text-[64px]"
    [ngClass]="{ 'text-center': content().alignment === 'center' }"
    [innerHTML]="sanitizedContent()"
  ></h1>
}

@if (content().type === "h2") {
  <h2
    class="font-roboto-condensed text-[32px] font-bold leading-10 text-cg-anthracite cgxT:text-5xl cgxT:leading-[64px]"
    [ngClass]="{ 'text-center': content().alignment === 'center' }"
    [innerHTML]="sanitizedContent()"
  ></h2>
}

@if (content().type === "h3") {
  <h3
    class="font-roboto-condensed text-2xl font-bold text-cg-anthracite cgxT:text-[32px] cgxT:leading-10"
    [ngClass]="{ 'text-center': content().alignment === 'center' }"
    [innerHTML]="sanitizedContent()"
  ></h3>
}

@if (content().type === "h4") {
  <h4
    class="font-roboto-condensed text-[18px] font-bold leading-6 text-cg-anthracite cgxT:text-2xl"
    [ngClass]="{ 'text-center': content().alignment === 'center' }"
    [innerHTML]="sanitizedContent()"
  ></h4>
}
