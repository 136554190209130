import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "cgx-slider-item",
  templateUrl: "./slider-item.component.html",
  styleUrl: "./slider-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SliderItemComponent {}
