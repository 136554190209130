<div>
  <label
    class="flex min-w-[328px] max-w-[508px] select-none items-center gap-2 font-roboto-condensed text-lg font-bold leading-6 text-cg-anthracite"
    [ngClass]="{
      'rounded bg-cg-gray-light-70 p-3 hover:bg-cg-anthracite-light-90': content().variation === 'l',
      'bg-cg-yellow hover:bg-cg-yellow-hover': content().variation === 'l' && !!value
    }"
  >
    <input
      [id]="content().id"
      class="size-5 appearance-none bg-red-500"
      type="checkbox"
      [name]="content().name"
      [checked]="!!value"
      (change)="onChange()"
    />
    {{ content().title }}
  </label>
</div>
